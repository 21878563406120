import "core-js/modules/es.array.map";
import "core-js/modules/es.array.sort";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.parse-float";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import bonusCustomCond from "./bonusCustomCond";
import bonusCond from "./bonusCond";
import exaCond from './exaCond';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    bonusCustomCond: bonusCustomCond,
    bonusCond: bonusCond,
    exaCond: exaCond
  },
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    var _this = this;

    return {
      reloadTimer1: 'reloadTimer1',
      reloadTimer2: 'reloadTimer2',
      reloadTimer3: 'reloadTimer3',
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "条件配置",
          "type": "success"
        }, {
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "0",
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "是否启用",
          "rules": [],
          "show": 1,
          "type": "switch",
          "width": "0",
          "prop": "status"
        }, {
          "add": 1,
          "desc": "重0开始，涉及数据库奖金字段",
          "detail": 1,
          "groupId": "",
          "label": "唯一标识",
          "required": 1,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "0",
          "prop": "tag"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "奖金名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "0",
          "prop": "name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "算法",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "0",
          "prop": "bonusType"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "结算周期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "0",
          "prop": "period"
        }, {
          "add": 1,
          "defaultValue": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "前端是否显示",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "0",
          "prop": "webShow"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "前端显示名称",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "0",
          "prop": "webName"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "奖金描述",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "0",
          "prop": "remark"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "计算顺序",
          "required": 1,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "0",
          "prop": "sort"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "特殊计算标识",
          "detail": 1,
          "groupId": "",
          "label": "特殊标识",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "0",
          "prop": "exaAction"
        }, {
          "add": 1,
          "defaultValue": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "是否发放",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "isExamine"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "如发放到奖金池，奖金不会进入奖金表也不会发放，直接存入奖金池",
          "detail": 1,
          "groupId": "",
          "label": "是否发放到奖金池",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "exaPeriod"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "用于计算沉淀，0不计算，沉淀后进入奖金池",
          "detail": 1,
          "groupId": "",
          "label": "最大波比",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "exaCond"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "period": {
            "data": [{
              "key": 0,
              "value": "日结"
            }, {
              "key": 1,
              "value": "周结"
            }, {
              "key": 2,
              "value": "月结"
            }, {
              "key": 3,
              "value": "秒结"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "bonusType": {
            "data": [{
              "key": 0,
              "value": "平级奖"
            }, {
              "key": 1,
              "value": "平均分红"
            }, {
              "key": 100,
              "value": "自定义"
            }, {
              "key": 101,
              "value": "特殊算法"
            }, {
              "key": 2,
              "value": "区域代理奖"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "isExamine": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "webShow": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 5,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "exaPeriod": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 7,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {
          "status": {
            "activeValue": 1,
            "id": 2,
            "inactiveValue": 0,
            "integer": 1,
            "relation": false,
            "relationProps": []
          }
        },
        "title": "奖金",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;

    var sortBonus = function sortBonus(a, b) {
      if (parseInt(a.sort) > parseInt(b.sort)) {
        return 1;
      } else if (parseInt(a.sort) < parseInt(b.sort)) {
        return -1;
      }

      return 0;
    };

    this.config.bonusData.sort(sortBonus);
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      for (var i in this.config.bonusData) {
        this.config.bonusData[i].exaCond = parseFloat(this.config.bonusData[i].exaCond);
      }

      resolve({
        total: 0,
        data: this.config.bonusData,
        isSplit: false
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        var tmpData = this.$tempModel.deepCopy(this.config.bonusData);

        for (var i in tmpData) {
          if (tmpData[i].id === row.id) {
            var tmp = this.$tempModel.deepCopy(row);

            for (var key in tmp) {
              if (params.hasOwnProperty(key)) {
                tmp[key] = params[key];
                console.log(key, tmp[key], params[key]);
              }
            }

            tmpData[i] = tmp;
            this.config.bonusData = tmpData;
            break;
          }
        }
      } else {
        params.id = this.config.bonusId++;
        params.conditions = null;
        params.isExamine = 0;
        params.exaAction = '';
        params.exaCond = 0;
        params.exaPeriod = 0;
        this.config.bonusData.push(params);
      }

      resolve();
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      var tmp = this.$tempModel.deepCopy(this.config.bonusData);
      tmp.map(function (item, idx) {
        if (item.id === ids[0]) {
          tmp.splice(idx, 1);
          return;
        }
      });
      this.config.bonusData = tmp;
      resolve();
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      console.log('row', row);

      if (btnIndex === 0) {
        if (row.bonusType === 100) {
          this.$refs.customCond.show(row, function () {
            _this2.table.reloadData();

            _this2.reloadTimer1 = new Date().getTime();
          });
        } else if (row.bonusType === 101) {
          this.$alert('特殊算法不需要设置', '提示');
        } else {
          this.$refs.cond.show(row, function () {
            _this2.table.reloadData();

            _this2.reloadTimer2 = new Date().getTime();
          });
        }
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {
      //列表中switch变化事件
      row[prop] = val;
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};