import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/kunyu/sdKunYuAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import icon from './data/index';
export default {
  name: 'd2-icon-select',
  props: {
    // 值
    value: {
      type: String,
      required: false,
      default: ''
    },
    // 占位符
    placeholder: {
      type: String,
      required: false,
      default: '请选择'
    },
    // 弹出界面的方向
    placement: {
      type: String,
      required: false,
      default: 'right'
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    // 是否允许用户输入
    userInput: {
      type: Boolean,
      required: false,
      default: false
    },
    // 是否在选择后自动关闭
    autoClose: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function data() {
    return {
      // 绑定弹出框
      pop: false,
      // 所有图标
      icon: icon,
      // 组件内输入框的值
      currentValue: '',
      // 搜索的文字
      searchText: '',
      // 不是搜索的时候显示的折叠面板绑定的展开数据
      collapseActive: [] // collapseActive: [...Array(icon.length)].map((e, i) => i)

    };
  },
  computed: {
    // 输入框上绑定的设置
    bind: function bind() {
      return _objectSpread({
        placeholder: this.placeholder,
        clearable: this.clearable
      }, this.$attrs);
    },
    // 是否在搜索
    searchMode: function searchMode() {
      return !!this.searchText;
    },
    // 过滤后的图标
    iconFilted: function iconFilted() {
      var _this = this;

      return this.icon.map(function (iconClass) {
        return {
          title: iconClass.title,
          icon: iconClass.icon.filter(function (icon) {
            return icon.indexOf(_this.searchText) >= 0;
          })
        };
      }).filter(function (iconClass) {
        return iconClass.icon.length > 0;
      });
    }
  },
  watch: {
    value: function value(_value) {
      this.currentValue = _value;
    }
  },
  created: function created() {
    this.currentValue = this.value;
  },
  methods: {
    selectIcon: function selectIcon() {
      var iconName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      this.$emit('input', iconName);

      if (iconName && this.autoClose) {
        this.pop = false;
      }
    }
  }
};